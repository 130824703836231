import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Moment from "react-moment";
import HTMLReactParser, {
  DomElement,
  HTMLReactParserOptions,
} from "html-react-parser";

import Author from "./author";
import Layout from "./layout";
import ShortcodeParserService from "../services/shortcode-parser-service";
import { FormWrapper } from "./leads-components/form-wrapper";
import HTMLParser from "./html-parser";

// TODO Valutare se tipizzare il campo data nelle props
type CaseStudyProps = {
  data: any;
};

const CaseStudy: React.FC<CaseStudyProps> = (
  props: CaseStudyProps
): JSX.Element => {
  const [parsedContent, setParsedContent] = useState<
    JSX.Element | JSX.Element[]
  >();

  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: (node: DomElement) => {
        if (node.data) {
          const shortcodes = ShortcodeParserService.extractWpShortcode(
            node.data
          );

          if (shortcodes) {
            const shortcodeParsed = ShortcodeParserService.parse(shortcodes[0]);
            return <FormWrapper {...shortcodeParsed.attributes}></FormWrapper>;
          }
        }
      },
    };

    setParsedContent(HTMLReactParser(props.data.page.content, options));
  }, []);

  return (
    <Layout seo={props.data.page?.seo}>
      <article className="w-full mx-auto space-y-8">
        <div className="w-full flex justify-center space-x-40">
          <div className="py-32 flex flex-col prose lg:prose ">
            <Moment locale="it" format="DD MMMM YYYY">
              {props.data.page?.date}
            </Moment>
            <h1>{props.data.page.title}</h1>
            <div className="inline-flex items-center space-x-3 text-sm">
              <Author author={props.data.page?.author.node}></Author>
            </div>
            <div className="w-full right-0">
              {!parsedContent ? (
                <HTMLParser>{props.data.page.content}</HTMLParser>
              ) : (
                parsedContent
              )}
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default CaseStudy;
