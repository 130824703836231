import React from "react";
import { graphql } from "gatsby";
import CaseStudy from "../components/case-study";

export default ({ data }) => <CaseStudy data={data}></CaseStudy>;


export const query = graphql`
  query caseStudy($id: String!) {
    site {
      siteMetadata {    
        title
      }
    }
    page: wpCaseStudy(id: { eq: $id }) {
      id
      uri
      title
      content
      date
      tags {
        nodes {
          id
          name
          description
          uri
          slug
        }
      }
      categories {
        nodes {
          id
          name
          slug
        }
      }
      author {
        node {
          name
          avatar {
            url
          }
        }
      }
      ... WpSEOCaseStudy
    }
  }
`;

